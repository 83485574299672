var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      attrs: {
        width: _vm.isFullScreen ? _vm.screenWidth : _vm.drawerWidth,
        visible: _vm.show,
        closable: false,
        wrapClassName: "drawer-layout",
      },
      on: { close: _vm.closed },
    },
    [
      _c("div", { staticClass: "pb-14" }, [
        !_vm.showForm
          ? _c(
              "div",
              { staticClass: "detail-wrap" },
              [
                !_vm.isEdit
                  ? _c(
                      "div",
                      { staticClass: "flex jc-end mb-4" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary", icon: "edit" },
                            on: { click: _vm.editForm },
                          },
                          [_vm._v("编辑")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._t("default"),
              ],
              2
            )
          : _c(
              "div",
              { staticClass: "form-wrap" },
              [
                _c(
                  "a-form-model-item",
                  [
                    _c(
                      "a-steps",
                      { attrs: { current: _vm.currentStep, size: "small" } },
                      _vm._l(_vm.steps, function (one) {
                        return _c("a-step", {
                          key: one.title,
                          attrs: { title: one.title },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._t("form"),
              ],
              2
            ),
        _c(
          "div",
          {
            staticClass:
              "bg-white absolute inset-x-0 bottom-0 h-16 flex jc-end items-center px-4 border-top",
          },
          [
            _c("a-button", { staticClass: "mr-4", on: { click: _vm.closed } }, [
              _vm._v(" 取消 "),
            ]),
            _c(
              "a-button",
              {
                attrs: { type: "primary", loading: _vm.processing },
                on: { click: _vm.save },
              },
              [_vm._v(" " + _vm._s(_vm.nextText) + " ")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex", attrs: { slot: "title" }, slot: "title" },
        [
          _c("div", { staticClass: "text-lg text-medium flex-1" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c(
            "a",
            {
              staticClass: "ml-4",
              on: {
                click: function ($event) {
                  _vm.isFullScreen = !_vm.isFullScreen
                },
              },
            },
            [
              _c("a-icon", {
                staticClass: "text-xl tc-gray-600",
                attrs: {
                  type: !_vm.isFullScreen ? "fullscreen" : "fullscreen-exit",
                },
              }),
            ],
            1
          ),
          _c(
            "a",
            { staticClass: "ml-4", on: { click: _vm.closed } },
            [
              _c("a-icon", {
                staticClass: "text-xl tc-gray-600",
                attrs: { type: "close" },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }