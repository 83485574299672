<template>
  <a-drawer
    :width="isFullScreen ? screenWidth : drawerWidth"
    :visible="show"
    :closable="false"
    wrapClassName="drawer-layout"
    @close="closed"
  >
    <div class="pb-14">
      <div class="detail-wrap" v-if="!showForm">
        <div class="flex jc-end mb-4" v-if="!isEdit">
          <a-button type="primary" icon="edit" @click="editForm">编辑</a-button>
        </div>
        <slot></slot>
      </div>
      <div class="form-wrap" v-else>
        <a-form-model-item>
          <a-steps :current="currentStep" size="small">
            <a-step v-for="one in steps" :key="one.title" :title="one.title" />
          </a-steps>
        </a-form-model-item>
        <slot name="form"></slot>
      </div>

      <div class="bg-white absolute inset-x-0 bottom-0 h-16 flex jc-end items-center px-4 border-top">
        <a-button class="mr-4" @click="closed"> 取消 </a-button>
        <a-button type="primary" @click="save" :loading="processing"> {{ nextText }} </a-button>
      </div>
    </div>
    <div class="flex" slot="title">
      <div class="text-lg text-medium flex-1">{{ title }}</div>
      <a @click="isFullScreen = !isFullScreen" class="ml-4">
        <a-icon :type="!isFullScreen ? 'fullscreen' : 'fullscreen-exit'" class="text-xl tc-gray-600" />
      </a>
      <a @click="closed" class="ml-4">
        <a-icon type="close" class="text-xl tc-gray-600" />
      </a>
    </div>
  </a-drawer>
</template>

<script>
export default {
  props: {
    title: String,
    show: {
      type: Boolean,
      default: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    steps: {},
  },
  watch: {
    show(n) {
      this.$emit('show', 0)
      if (n) {
        if (this.isForm) {
          this.showForm = true
        }
      } else {
        this.isEdit = false
        this.showForm = false
      }
    },
    isForm(n) {
      console.log('新建表单', n)
    },
  },
  data() {
    return {
      isFullScreen: false,
      screenWidth: 0,
      drawerWidth: 768,
      isEdit: false,
      showForm: false,
      currentStep: 0,
      processing: false,
    }
  },
  methods: {
    editForm() {
      this.isEdit = true
      this.showForm = true
      this.$emit('edit')
    },
    closed() {
      this.$emit('closed', false)
      this.currentStep = 0
    },
    save() {
      this.processing = true
      this.$emit('save', this.currentStep, (ok) => {
        this.processing = false
        if (ok) {
          this.currentStep++
          this.$emit('show', this.currentStep)
        }
      })
      // this.$emit('save')
    },
  },
  created() {},
  mounted() {
    this.screenWidth = document.documentElement.clientWidth
  },
  computed: {
    nextText: function () {
      return this.currentStep >= this.steps.length - 1 ? '完成' : '下一步'
    },
  },
}
</script>

<style lang='stylus'>
.drawer-layout {
  .ant-drawer-close {
    display: none;
  }
}
</style>